<template>
  <div>
    <div class="a-breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item to="/user/model">模型文件夹</el-breadcrumb-item>
        <el-breadcrumb-item
          ><i class="el-icon-folder"></i
          ><span style="margin-left: 0.5rem; cursor: pointer">{{
            folderName
          }}</span></el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <el-table
      :data="tableData"
      v-loading="loading.table"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-table-column label="模型名称" prop="name">
        <template slot-scope="scope">
          <i class="el-icon-document"></i
          ><span style="margin-left: 0.5rem">{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="文件大小" prop="fileSizeMB">
        <template slot-scope="scope"> {{ scope.row.fileSizeMB }} MB </template>
      </el-table-column>
      <el-table-column label="修改时间" prop="lastUpdateTime" />
      <el-table-column label="上传时间" prop="uploadTime" />
    </el-table>
    <div class="a-pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.currentPage"
        :page-sizes="pageInfo.pageSizes"
        :page-size="pageInfo.pageSize"
        :layout="pageInfo.layout"
        :total="pageInfo.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      pageInfo: {
        pageSizes: [20, 50, 100],
        pageSize: 20,
        layout: "total, sizes, prev, pager, next, jumper",
        currentPage: 1,
        total: 1,
      },
      loading: {
        table: true,
      },
      customerId: localStorage.getItem("customerId"),
      folderId: this.$route.params.folderId,
      folderName: JSON.parse(localStorage.getItem("model")).folderName,
    };
  },
  mounted() {
    this.getModelOfFolder();
  },
  methods: {
    /**获取文件夹下模型文件列表请求 */
    getModelOfFolder() {
      const folderId = this.folderId;
      let page = {
        pageIndex: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
      };
      if (folderId) {
        this.$axios.getModelOfFolder({ ...page, folderId }).then((res) => {
          if (!res.error) {
            this.pageInfo.total = res.totalCount;
            this.tableData = res.data.map((model) => {
              return {
                ...model,
                uploadTime: this.$utils.dateToString(model.uploadTime),
              };
            });
          } else {
            this.$notify.error({
              title: "提示",
              message: res.error,
              duration: 2000,
            });
          }
          this.loading.table = false;
        });
      }
    },
    /**删除文件夹 */
    handleDeleteFolder(row) {
      console.log(row);
      this.$axios.deleteFolder({ folderId: row.id }).then((res) => {
        console.log(res);
      });
    },
    /**分页 */
    // 页面尺寸改变
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.getFolderList();
    },
    // 当前页改变
    handleCurrentChange(val) {
      this.pageInfo.currentPage = val;
      this.getFolderList();
    },
  },
};
</script>
